import { Injectable, NgZone } from '@angular/core';
import { Network } from '@capacitor/network';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { timer } from 'rxjs';

export const CONNECTED = 0;
export const NO_CONNECTION = 1;
export const LIMITED_CONNECTION = 2;
export const RECONNECTING = 3;

const TIMEOUT = 2 * 1000;
const MAX_RETRIES = 1;
const OFFSET = 1 * 1000;

export class Status {
  public state: Number;
  public reconnecting: Boolean;

  constructor(
    state: Number,
    reconnecting: Boolean
  ) {
    this.state = state;
    this.reconnecting = reconnecting;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ConnectionStateService {

  public statusObserver: Observable<{state: Status}>;

  log: string[];
  private status: Number;
  private reconnecting;
  private statusSubscriber;
  private timeout = TIMEOUT;
  private maxRetries = MAX_RETRIES;
  private offset = OFFSET;
  private retryCall = null;
  private retries = 0;
  private lastTimeRequest = 0;

  constructor(
    private http: HttpClient,
    private zone: NgZone
  ) {
    this.status = CONNECTED;
    this.statusObserver = new Observable(subscriber => {
      this.statusSubscriber = subscriber;
      Network.getStatus().then(this.updateStatus.bind(this));
    });

    Network.addListener('networkStatusChange', (status) => {
      this.zone.run(() => {
        this.updateStatus(status);
      });
    });
  }

  private updateStatus(status) {
    console.log('[service] update status', status);
    if (!this.statusSubscriber) {
      return;
    }
    if (status.connected) {
      if (this.status !== CONNECTED) {
        this.status = RECONNECTING;
        this.reconnecting = true;
        this.retries = 0;
        this.tryReconnect();
      } else {
        this.reconnecting = false;
        this.status = CONNECTED;
      }
    } else {
      this.reconnecting = false;
      this.status = NO_CONNECTION;
    }

    this.statusSubscriber.next({ state: new Status(this.status, this.reconnecting)});
  }

  public update(limitedConnection = false) {
    if (!this.statusSubscriber) {
      return;
    }

    this.statusSubscriber.next({ state: new Status(this.status, this.reconnecting)});

    if (this.status === NO_CONNECTION) {
      return;
    }
    if (limitedConnection) {
      this.status = RECONNECTING;

      this.statusSubscriber.next({ state: new Status(this.status, this.reconnecting)});

    }
    if (this.status === LIMITED_CONNECTION || this.status === RECONNECTING) {
      this.status = RECONNECTING;
      this.reconnecting = true;
      this.tryReconnect();
    }
  }

  private tryReconnect() {
    console.log('[service] try reconnect', this.status, this.reconnecting, this.retries);
    if (this.status === NO_CONNECTION || !this.reconnecting) {
      return;
    }
    if (this.retries > this.maxRetries) {
      this.retries = 0;
      this.status = LIMITED_CONNECTION;
      this.statusSubscriber.next({ state: new Status(this.status, this.reconnecting)});
      return;
    }
    if (this.retryCall) {
      return;
    }
    const timeStart = performance.now();

    this.retryCall = this.reconnect().subscribe(() => {
      this.retryCall = null;
      this.lastTimeRequest = performance.now() - timeStart;
      this.status = CONNECTED;
      this.reconnecting = false;
      this.retries = 0;
      this.statusSubscriber.next({ state: new Status(this.status, this.reconnecting)});
    }, () => {
      this.retryCall = null;
      this.lastTimeRequest = performance.now() - timeStart;
    }).add(() => {
      this.lastTimeRequest = performance.now() - timeStart;
      this.retries += 1;
      this.retryCall = null;
      setTimeout(() => {
        this.tryReconnect();
      }, this.offset);
    });

  }

  private reconnect() {
    return this.http.get('https://jsonplaceholder.typicode.com/todos/1')
    .pipe(
      takeUntil(timer(this.timeout))
    );
  }
}
