<mat-form-field appearance="outline" class="full-width" *ngIf="disabled">
  <mat-label>{{label}}</mat-label>
  <input type="text"
    [required]="mandatorySelection"
    matInput
    [value]="formGroup.controls.typed.value"
    [disabled]="disabled"
    (focusout)="focusOut()">
   <mat-error *ngIf="formGroup.controls.typed.hasError('invalid')">
       Opción no válida
   </mat-error>
   <mat-error *ngIf="formGroup.controls.typed.hasError('required')">
       El campo es obligatorio
   </mat-error>
</mat-form-field>

<form [formGroup]="formGroup" *ngIf="!disabled">
  <mat-form-field appearance="outline" class="full-width">
    <mat-label>{{label}}</mat-label>
    <input type="text"
      [required]="mandatorySelection || required"
      matInput
      [formControl]="typed"
      [matAutocomplete]="auto"
      [readonly]="readonly"
      [disabled]="disabled"
      (focusout)="focusOut()">
     <mat-error *ngIf="formGroup.controls.typed.hasError('invalid')">
         Opción no válida
     </mat-error>
     <mat-error *ngIf="!formGroup.controls.typed.hasError('invalid') && formGroup.controls.typed.hasError('required')">
         El campo es obligatorio
     </mat-error>
    <button mat-button *ngIf="unknown && !hide" matSuffix mat-icon-button (click)="inputAction()">
      <mat-icon>add_circle</mat-icon>
    </button>
    <mat-autocomplete #select autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="optionSelected($event)">
      <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
      <mat-option *ngFor="let option of filteredOptions" [value]="option">
        {{option.name}}
      </mat-option>
      </mat-autocomplete>
  </mat-form-field>
</form>
