import { Component, OnInit } from '@angular/core';
import { ApiManagerService } from '@ildes/services/api-manager.service';
import { FingerprintService } from '@ildes/services/fingerprint.service';
import { from } from 'rxjs';
import { concatMap, first } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { SessionStore } from '@ildes/stores/session.store';
import { Location } from '@angular/common';
import { NativeLayerService } from '@ildes/services/native-layer.service';
import { FileHelperService } from '@ildes/services/file-helper.service';
/*
  TODO:
  - 1) si la tarea no está completa:
    a) Si he firmado yo => no me aparece el botón. Me aparece un disclaimer diciendo que faltan usuarios por fimar
    b) No he firmado yo => me aparece el botón
*/
function arrayBufferToBase64( buffer ) {
	var binary = '';
	var bytes = new Uint8Array( buffer );
	var len = bytes.byteLength;
	for (var i = 0; i < len; i++) {
		binary += String.fromCharCode( bytes[ i ] );
	}
	return window.btoa( binary );
}

@Component({
  selector: 'app-task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.css']
})
export class TaskDetailComponent implements OnInit {
  id = '';
  signin;
  loading;
  downloading;
  attachments = [];
  files = [];
  subscriptions = [];
  task;
  usersPendingToSign = false;
  fingerprintEnabled = false;
  availableBiometry = false;
  constructor(
    private route: ActivatedRoute,
    private apiManager: ApiManagerService,
    private fingerprint: FingerprintService,
    private location: Location,
    private nativeLayer: NativeLayerService,
    private fileHelper: FileHelperService
  ) { }

  async enrollFingerprint() {
    if (!this.nativeLayer.isNative) {
      return;
    }

    await this.fingerprint.enroll();

    this.fingerprint.isEnrolled().then((result) => {
      this.fingerprintEnabled = result;
    });
  }

  get fingerprintAlertText() {
    if (!this.availableBiometry) {
      return 'El dispositivo no es compatible con la huella. No podrás firmar documentos con este dispositivo.';
    } else if (!this.fingerprintEnabled) {
      return 'Aun no has activado la huella. Necesitas activar la huella para completar el cierre de los mantenimientos.'
    }

    return '';
  }

  open(fileName) {
    this.downloading = true;
    this.fileHelper.downloadPdf(fileName).subscribe(() => {
      this.downloading = false;
    }, () => {
      this.downloading = false;
    })
  }

  sign() {

    const file = this.attachments[0];
    this.signin = true
    return this.apiManager.getFile(file, {}, { contentType: 'application/pdf', error: true }).pipe(
      concatMap((data) =>
        from(data.arrayBuffer()).pipe(
          concatMap((arrayBuffer) =>
            from(this.fingerprint.digestAndSignfile(arrayBuffer)).pipe(
              concatMap((digest) => this.apiManager.signFile(file, this.id, arrayBufferToBase64(digest)))
            )
          )
        )
      )
    ).subscribe((data) => {
      this.location.back();
      this.signin = false;
    }, (e) => {
      this.signin = false;
    });
  }

  ngOnInit(): void {
    if (this.nativeLayer.isNative) {
      this.fingerprint.isCompatible().then((result) => {
        this.availableBiometry = result;

        this.fingerprint.isEnrolled().then((result) => {
          this.fingerprintEnabled = result;
        });
      });
    }
    this.subscriptions.push(
      SessionStore.getInstance().get$().subscribe((data) => {
        const userId = data?.user?.id;

        this.route.queryParams.pipe(first()).subscribe(params => {
          this.id = params.id;
          this.loading = true;
          this.apiManager.getTaskDetail(this.id).subscribe(({ data }: any) => {
            this.task = data;
            this.attachments = [data.metadata.documentName];
            this.usersPendingToSign = this.task.state === 'PENDING' && this.task.usersWhoPerformTheTask.indexOf(userId) >= 0;
            this.loading = false;
          }, () => {
            this.loading = false;
          });
        });
      })
    );
  }

  get title() {
    switch (this.task?.subtype) {
      case 'WORK_AT_HIGH_DAILY_PERMISSION':
          return 'Firmar documentación para trabajo en alturas';
      case 'WORK_AT_MAINTENANCE_PERMISSION':
        return 'Firmar documentación para trabajo en mantenimiento';
      default:
        break;
    }
  }
}
