<simple-header class="padding-8" title="Crear luminaria" [back]="true" icon="close" *ngIf="!dialogMode"></simple-header>
<div class="container" [ngClass]="{'scrolling': !dialogMode}">
  <mat-vertical-stepper [linear]="true" #stepper>
    <mat-step [stepControl]="firstFormGroup">
      <form [formGroup]="firstFormGroup" autocomplete="off">
        <ng-template matStepLabel> Identificación de la luminaria</ng-template>
        <div class="inline-flex padding-top-8">
          <predictive-searcher
            [required]="true"
            [readonly]="projectState"
            formControlName="selectedProjectForm"
            label="Proyecto"
            [service]="searchProjectAdapter"
            class="column margin-right-8"></predictive-searcher>
          <mat-form-field appearance="outline" class="column">
            <mat-label>Número</mat-label>
            <input [readonly]="numberState" matInput maxlength="20" formControlName="number">
            <mat-error>
                El número es obligatorio
            </mat-error>
          </mat-form-field>
        </div>
        <div class="inline-flex">
          <div class="inline-flex column">
            <predictive-searcher
              [required]="true"
              label="Tecnología"
              formControlName="technologyForm"
              [service]="searchLuminaryTypesAdapter"
              class="column margin-right-8"></predictive-searcher>
            <mat-form-field appearance="outline" class="column margin-right-column-8">
              <mat-label>Potencia</mat-label>
              <input formControlName="power" matInput maxlength="20">
              <mat-error>
                  La potencia es obligatoria
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="inline-flex">
          <predictive-searcher
            label="Fabricante"
            formControlName="makerForm"
            [service]="searchMakerTypesAdapter"
            class="column margin-right-column-8"></predictive-searcher>
          <predictive-searcher
            label="Modelo"
            formControlName="modelForm"
            [service]="searchModelAdapter"
            class="column"></predictive-searcher>
        </div>
        <div class="">
          <input-place
              label="Ubicación"
              placeholder="CLL 33 45"
              dark
              [requiredGeoposition]="true"
              formControlName="location"
              appearance="fill"></input-place>
        </div>
        <div>
        </div>
        <div class="inline-flex padding">
          <predictive-searcher
            label="Barrio"
            formControlName="neighborhoodForm"
            [service]="searchNeighborhoodAdapter"
            class="column margin-right-column-8"></predictive-searcher>
          <dropdown-selector class="column"  label="Tipo de vía Retilap" [types]="retilap" [(selected)]="selectedRetilap"></dropdown-selector>
        </div>
        <div class="inline-flex">
          <mat-form-field appearance="outline" class="column">
            <mat-label>Observaciones de ruta o punto de referencia</mat-label>
            <input matInput formControlName="pathObservations"/>
          </mat-form-field>
        </div>
        <div>
          <button mat-button matStepperNext>Siguiente</button>
          <button mat-button (click)="stepper.selectedIndex=4" color="primary">Omitir resto de datos y guardar</button>
        </div>
      </form>
    </mat-step>
    <mat-step [optional]="true" [stepControl]="secondFormGroup">
      <ng-template matStepLabel>Características de poste y luminaria</ng-template>
      <form [formGroup]="secondFormGroup" class="padding-top-8 padding-bottom-8">
        <div class="inline-flex">
          <mat-form-field appearance="outline" class="column">
            <mat-label>Número de poste</mat-label>
            <input matInput maxlength="20" formControlName="postNumber">
          </mat-form-field>
        </div>
        <div>
          <div class="inline-flex">
            <dropdown-selector class="column margin-right-column-8" label="Tipo" [types]="types" [(selected)]="selectedType"></dropdown-selector>
            <dropdown-selector class="column"  label="Especial" [types]="special" [(selected)]="selectedSpecial"></dropdown-selector>
          </div>
          <div class="inline-flex">
            <dropdown-selector class="column margin-right-column-8" label="Anomalía" [types]="anomaly" [(selected)]="selectedAnomaly"></dropdown-selector>
            <dropdown-selector class="column"  label="Tipo de encendido" [types]="switchedon" [(selected)]="selectedSwitchedon"></dropdown-selector>
          </div>
          <div class="inline-flex">
            <dropdown-selector class="column margin-right-column-8" label="Tipo de poste" [types]="post" [(selected)]="selectedPostType"></dropdown-selector>
            <dropdown-selector class="column"  label="Clase de poste" [types]="postClass" [(selected)]="selectedPostClass"></dropdown-selector>
          </div>
          <div class="inline-flex">
            <mat-form-field appearance="outline" class="column">
              <mat-label>Altura de poste</mat-label>
              <input matInput formControlName="postHeight">
            </mat-form-field>
          </div>
          <div class="inline-flex">
            <dropdown-selector class="column" label="Zona de instalación" [types]="installationZones" [(selected)]="selectedInstallationZone"></dropdown-selector>
          </div>
          <div class="inline-flex">
            <dropdown-selector class="column" label="Tipo de espacio iluminado" [types]="spaceTypes" [(selected)]="selectedSpaceType"></dropdown-selector>
          </div>
          <div class="inline-flex">
            <mat-form-field appearance="outline" class="column">
              <mat-label>Observaciones</mat-label>
              <textarea rows="2" matInput formControlName="observations"></textarea>
            </mat-form-field>
          </div>
          <div class="margin-bottom-16">
            <mat-checkbox [(ngModel)]="particular" [ngModelOptions]="{standalone: true}">Uso particular</mat-checkbox>
          </div>
        </div>
        <gallery-list [(photos)]="photos" [maxPhotos]="5"></gallery-list>
        <div>
          <button mat-button matStepperNext color="primary">Siguiente</button>
        </div>
      </form>
    </mat-step>
    <mat-step [optional]="true" [stepControl]="thirdFormGroup">
      <ng-template matStepLabel>Características de transformador y red</ng-template>
      <form [formGroup]="thirdFormGroup" class="padding-top-8 padding-bottom-8">
        <div class="inline-flex">
          <mat-form-field appearance="outline" class="column margin-right-8">
            <mat-label>Calibre de red eléctrica</mat-label>
            <input matInput formControlName="conductorGauge">
          </mat-form-field>
          <dropdown-selector label="Red eléctrica" [types]="electricNetwork" [(selected)]="selectedElectricNetwork"></dropdown-selector>
        </div>

        <div class="inline-flex">
          <mat-form-field appearance="outline" class="column margin-right-8">
            <mat-label>Número de transformador</mat-label>
            <input matInput maxlength="20" formControlName="transformerNumber">
          </mat-form-field>
          <dropdown-selector class="column" label="Material red electrica" [types]="electricalNetworkMaterialTypes" [(selected)]="selectedElectricalNetworkMaterialType"></dropdown-selector>
        </div>

        <dropdown-selector class="column" label="Instalación de red eléctrica" [types]="powerNetworkInstallationTypes" [(selected)]="selectedElectricalNetworkInstallationType"></dropdown-selector>
        <dropdown-selector class="column" label="Tipo de transformador" [types]="useTransformerTypes" [(selected)]="selectedUseTransfromerType"></dropdown-selector>
        <dropdown-selector class="column" label="Instalación transformador" [types]="transformerInstallationTypes" [(selected)]="selectedTransformerInstallationType"></dropdown-selector>
        <div *ngIf="selectedElectricalNetworkInstallationTypeIsSub">
          <button mat-button matStepperNext color="primary">Siguiente</button>
        </div>
        <div *ngIf="!selectedElectricalNetworkInstallationTypeIsSub">
          <button mat-button (click)="stepper.selectedIndex=4" color="primary">Siguiente</button>
        </div>
      </form>
    </mat-step>
    <mat-step [optional]="true" [editable]="selectedElectricalNetworkInstallationTypeIsSub">
      <ng-template matStepLabel>Características de canalización</ng-template>
      <div class="padding-top-8" *ngIf="selectedElectricalNetworkInstallationTypeIsSub">
        <dropdown-selector class="column" label="Tipo de canalización" [types]="useCanalizationTypes" [(selected)]="selectedUseCanalizationType"></dropdown-selector>
        <div class="margin-bottom-16">
          <mat-checkbox [(ngModel)]="inspectionBox" [ngModelOptions]="{standalone: true}">Caja de inspección</mat-checkbox>
        </div>
        <dropdown-selector class="column" label="Tipo de ductería" [types]="pipeTypes" [(selected)]="selectedPipeType"></dropdown-selector>
        <dropdown-selector class="column" label="Tipo de zona de ductería" [types]="pipeZoneTypes" [(selected)]="selectedPipeZoneType"></dropdown-selector>
        <button mat-button matStepperNext color="primary">Siguiente</button>
      </div>
    </mat-step>
    <mat-step #finalStep>
      <ng-template matStepLabel>Confirmación</ng-template>
      <resume-luminary
        [number]="number"
        [projectName]="project?.name || selectedProject?.name"
        [latitude]="latitude"
        [longitude]="longitude"
        [address]="address"
        [technology]="technology"
        [power]="power"
        [maker]="maker"
        [model]="model"
        [neighborhood]="neighborhood"
        [retilap]="selectedRetilap?.description"
        [special]="selectedSpecial?.description"
        [anomaly]="selectedAnomaly?.description"
        [switchedon]="selectedSwitchedon?.description"
        [electricNetwork]="selectedElectricNetwork?.description"
        [postType]="selectedPostType?.description"
        [postClass]="selectedPostClass?.description"
        [particularUse]="particular"
        [type]="selectedType?.description"
      ></resume-luminary>
      <div class="align-center padding-top-32">
        <div class="padding-bottom-8">
          <button (click)="create()" [disabled]="loading" mat-raised-button color="primary" class="button-size-200">Guardar</button>
        </div>
        <div>
          <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
        </div>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</div>
