import { Injectable } from '@angular/core';
import { ApiManagerService } from './api-manager.service';
import Fingerprint from '../../plugins/fingerprint';
import { ToastService } from './toast.service';

const { subtle } = window.crypto;
const ALGORITHM = 'SHA-256';

function ab2str(buf) {
  return String.fromCharCode.apply(null, new Uint8Array(buf));
}

function str2ab(str) {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

@Injectable({
  providedIn: 'root'
})
export class FingerprintService {

  public async isEnrolled() {
    const result = await Fingerprint.isEnrolled();

    return result.isEnabled;
  }

  public async disable() {
    await Fingerprint.disable();

    // TODO: hacemos algo para llevar la trazabilidad en el backend?
  }

  private getHexFromUintArray(uIntArray): string {
    const hashArray = Array.from(new Uint8Array(uIntArray)); // convert buffer to byte array

    return hashArray
        .map((b) => b.toString(16).padStart(2, '0'))
        .join(''); // convert bytes to hex string
  }
  // TODO: el generateKey activará la huella y generará el keyPair

  private async performDocumentDigest(buffer): Promise<string> {
    const digest = await subtle.digest(ALGORITHM, buffer);
    const hashHex = this.getHexFromUintArray(digest)

    return hashHex;
  }

  private async getPrivateKey() {
    const result = await Fingerprint.getPrivateKey();
    const binaryDerString = atob(result.privateKey);
  // convert from a binary string to an ArrayBuffer
    const binaryDer = str2ab(binaryDerString);
    // https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/importKey#subjectpublickeyinfo
    return subtle.importKey(
      'pkcs8',
      binaryDer,
      {
        name: 'RSASSA-PKCS1-v1_5',
        hash: 'SHA-256',
      },
      true,
      ['sign']
    );
  }


  public async digestAndSignfile(document) {
    const privateKey = await this.getPrivateKey();
    const digest = await this.performDocumentDigest(document);

    const signedData = await subtle.sign(
        'RSASSA-PKCS1-v1_5',
        privateKey,
        new TextEncoder().encode(digest)
    );
    return signedData;
  }

  private async generateKey() {
    return subtle.generateKey({
      name: 'RSASSA-PKCS1-v1_5',
      modulusLength: 2048,
      publicExponent: new Uint8Array([1, 0, 1]),
      hash: 'SHA-256',
    },
      true,
      ['sign', 'verify']
    )
  }

  // TODO: eliminar keyPairs del servicio. Esto se almacenará de forma segura en el dispositivo

  async enroll() {
    const keyPair = await this.generateKey();
    const byteArrayPublicKey = await subtle.exportKey('spki', keyPair.publicKey);
    const byteArrayPrivateKey = await subtle.exportKey('pkcs8', keyPair.privateKey);

    const privateKey = btoa(ab2str(byteArrayPrivateKey));
    const publicKey = btoa(ab2str(byteArrayPublicKey));
    await Fingerprint.enroll({privateKey, publicKey});

    try {
      await this.apiManager.enrollmentFingerprint(publicKey).toPromise();

      this.toastService.show('Huella registrada', 'ok');
    } catch {
      this.toastService.show('No se pudo registrar su huella. Compruebe que tiene conexión e inténtelo de nuevo', 'warning');

      await this.disable();
    }
  }


  async isCompatible() {
    const result = await Fingerprint.canIUse();

    return result.canIUse;
  }


  constructor(
    private apiManager: ApiManagerService,
    private toastService: ToastService
  ) { }
}
